<template>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
            <b-link class="brand-logo">
<!--               start::logo-->
              <svg width="300" height="50" viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 25.1758C0 16.9173 6.43948 10.4778 14.1523 10.4778C19.6459 10.4778 23.8297 13.8976 23.8297 18.0815C23.8297 20.9556 21.9015 22.3017 19.9006 22.3017C17.6813 22.3017 16.3352 20.6282 16.3352 18.518C16.3352 16.5898 17.463 15.7531 17.463 14.1523C17.463 12.697 16.4443 11.3873 14.4797 11.3873C10.9144 11.3873 7.09435 15.4984 7.09435 21.9379C7.09435 29.1778 12.115 33.1797 17.0264 33.1797C21.283 33.1797 24.1572 30.2692 24.7029 27.5042L25.4669 27.6134C24.5937 33.9437 20.1552 39.1826 13.2064 39.1826C5.67548 39.1462 0 32.9978 0 25.1758Z" fill="#16191A"/>
                <path d="M24.8848 37.8365C26.7766 37.8365 28.1955 36.7087 28.1955 33.1433L28.5229 6.91244C28.5229 4.43852 27.7225 3.23793 25.285 3.20155V2.43755C28.7048 2.18288 32.8886 1.41887 36.0902 0L36.6359 0.254669V33.1433C36.6359 36.7087 38.0912 37.8365 39.983 37.8365V38.6005H24.9211V37.8365H24.8848Z" fill="#16191A"/>
                <path d="M40.0194 24.9939C40.0194 16.7354 46.2406 10.4778 53.626 10.4778C60.3929 10.4778 64.3221 15.6803 64.3221 21.1375H47.805C47.8414 28.9595 52.0616 33.2888 57.155 33.2525C61.3025 33.2525 64.031 30.3783 64.6495 27.5406L65.4135 27.6497C64.4312 34.1256 59.7017 39.219 52.9348 39.219C45.2219 39.1462 40.0194 32.7067 40.0194 24.9939ZM54.0262 20.1916C55.8089 20.1916 56.391 19.3912 56.391 17.1719C56.391 14.698 55.5906 11.2782 53.1894 11.2782C50.5336 11.2782 48.0597 15.3165 47.805 20.1916H54.0262Z" fill="#16191A"/>
                <path d="M82.2581 32.8159C81.5668 35.8355 78.6927 39.1462 74.1087 39.1462C69.7429 39.1462 66.505 36.2721 66.505 32.2702C66.505 28.8503 68.9426 26.1217 73.4174 25.7215L81.2394 25.0303L80.8028 21.7196C80.148 17.3538 78.0015 14.8435 74.5453 14.8435C71.5256 14.8435 69.3427 16.6626 68.2149 19.3912L67.4509 19.2457C68.5424 14.9163 72.7626 10.4414 78.7291 10.4414C84.4046 10.4414 87.97 14.1159 88.8431 20.4826L90.262 31.6517C90.5166 33.9073 91.4626 34.5622 92.1902 34.5622C92.8814 34.5622 93.4272 33.9801 93.6454 33.1069L94.3003 33.2161C94.1912 35.7992 92.1538 39.0371 88.3702 39.0371C84.6229 39.1099 82.8038 36.5995 82.2581 32.8159ZM78.1106 34.926C80.3663 34.926 81.9307 33.2888 82.149 31.7972L81.3486 25.9034L78.1106 26.2309C75.4184 26.4492 73.8904 28.1591 73.8904 30.5966C73.8904 32.8887 75.5276 34.926 78.1106 34.926Z" fill="#16191A"/>
                <path d="M93.9364 37.8365C95.8282 37.8365 97.2471 36.7087 97.2471 33.1433L97.2835 17.3538C97.2835 14.8799 96.5195 13.6793 94.0819 13.643V12.879C97.5018 12.6243 101.649 11.8603 104.814 10.4414L105.397 10.7688V16.226C106.27 13.4247 108.598 10.4414 112.054 10.4414C114.928 10.4414 116.529 12.6607 116.529 14.9163C116.529 17.0628 115.001 19.1729 112.418 19.1729C109.944 19.1729 109.289 17.2447 108.816 15.9714C108.489 15.0982 108.271 14.8435 107.725 14.8435C106.597 14.8435 105.578 16.4807 105.397 18.5544V33.0706C105.397 36.6359 107.216 37.7637 109.653 37.7637V38.5277H93.8636V37.8365H93.9364Z" fill="#16191A"/>
                <path d="M165.608 27.3587L166.372 27.2132C167.9 31.6881 171.465 38.1276 176.74 38.164C179.505 38.164 181.288 36.5268 181.288 34.3439C181.288 32.0883 179.869 30.6331 176.158 29.1414L172.12 27.3951C167.79 25.6124 165.426 22.9202 165.535 18.8091C165.68 13.8977 170.192 10.4414 175.794 10.4778C180.669 10.587 183.471 13.2428 184.562 10.114H185.363L185.945 21.3558L185.144 21.465C183.835 17.6449 180.124 11.351 175.794 11.351C173.248 11.3146 171.647 12.9154 171.647 15.0619C171.647 17.172 173.211 18.8455 176.413 20.1552L180.451 21.9015C185.326 23.9025 187.436 26.2673 187.327 30.1237C187.218 36.0539 182.088 39.0371 177.068 39.0371C171.865 39.0007 168.591 36.163 167.281 39.2554H166.408L165.608 27.3587Z" fill="#16191A"/>
                <path d="M187.691 48.3871C189.583 48.3871 191.002 47.2592 191.002 43.6939V17.3902C191.002 14.9163 190.237 13.7157 187.8 13.6793V12.9153C191.22 12.6607 195.404 11.8967 198.605 10.4778L199.151 10.8052V16.0441C199.806 14.0431 202.462 10.6597 207.191 10.6597C213.522 10.6597 218.033 16.8081 218.033 24.5573C218.033 32.7795 212.576 39.1826 205.117 39.1826C202.352 39.1826 200.57 38.2003 199.115 36.927V43.7303C199.115 47.2956 200.861 48.4234 203.116 48.4234V49.1875H187.654V48.3871H187.691ZM204.172 38.0548C207.482 38.0548 209.483 33.689 209.483 27.1404C209.483 18.0815 206.173 14.4433 203.044 14.4433C201.006 14.4433 199.697 15.9714 199.151 17.5358V28.2318C199.151 33.8709 200.788 38.0548 204.172 38.0548Z" fill="#16191A"/>
                <path d="M235.314 32.8159C234.623 35.8355 231.749 39.1462 227.165 39.1462C222.799 39.1462 219.561 36.2721 219.561 32.2702C219.561 28.8503 221.998 26.1217 226.473 25.7215L234.295 25.0303L233.859 21.7196C233.204 17.3538 231.057 14.8435 227.601 14.8435C224.582 14.8435 222.399 16.6626 221.271 19.3912L220.507 19.2457C221.598 14.9163 225.819 10.4414 231.785 10.4414C237.461 10.4414 241.026 14.1159 241.899 20.4826L243.318 31.6517C243.573 33.9073 244.518 34.5622 245.246 34.5622C245.937 34.5622 246.483 33.9801 246.701 33.1069L247.356 33.2161C247.247 35.7992 245.21 39.0371 241.426 39.0371C237.679 39.1099 235.823 36.5995 235.314 32.8159ZM231.13 34.926C233.386 34.926 234.95 33.2888 235.168 31.7972L234.368 25.9034L231.13 26.2309C228.438 26.4492 226.91 28.1591 226.91 30.5966C226.91 32.8887 228.547 34.926 231.13 34.926Z" fill="#16191A"/>
                <path d="M247.574 25.1758C247.574 16.9173 254.014 10.4778 261.727 10.4778C267.22 10.4778 271.404 13.8976 271.404 18.0815C271.404 20.9556 269.476 22.3017 267.475 22.3017C265.256 22.3017 263.91 20.6282 263.91 18.518C263.91 16.5898 265.037 15.7531 265.037 14.1523C265.037 12.697 264.019 11.3873 262.054 11.3873C258.489 11.3873 254.669 15.4984 254.669 21.9379C254.669 29.1778 259.689 33.1797 264.601 33.1797C268.858 33.1797 271.732 30.2692 272.277 27.5042L273.041 27.6134C272.168 33.9437 267.73 39.1826 260.781 39.1826C253.214 39.1462 247.574 32.9978 247.574 25.1758Z" fill="#16191A"/>
                <path d="M274.606 24.9939C274.606 16.7354 280.827 10.4778 288.212 10.4778C294.979 10.4778 298.908 15.6803 298.908 21.1375H282.391C282.428 28.9595 286.648 33.2888 291.741 33.2525C295.889 33.2525 298.617 30.3783 299.236 27.5406L300 27.6497C299.018 34.1256 294.288 39.219 287.521 39.219C279.808 39.1462 274.606 32.7067 274.606 24.9939ZM288.613 20.1916C290.395 20.1916 290.977 19.3912 290.977 17.1719C290.977 14.698 290.177 11.2782 287.776 11.2782C285.12 11.2782 282.646 15.3165 282.391 20.1916H288.613Z" fill="#16191A"/>
                <path d="M158.841 31.5789H116.711V38.6004H158.841V31.5789Z" fill="#16191A"/>
              </svg>
<!--              end::logo-->

                <!-- <h2 class="brand-text text-primary ml-1">
            Clearspace
          </h2> -->
            </b-link>

            <!-- <b-card-title class="mb-1">
          Welcome to Vuexy! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text> -->

            <!-- form -->
            <validation-observer ref="loginValidation" #default="{invalid}">
                <b-form class="auth-login-form mt-2" @submit.prevent>

                    <!-- email -->
                    <b-form-group label-for="email" label="Email">
                        <validation-provider #default="{ errors }" name="Email" rules="required|email">
                            <b-form-input id="email" v-model="email" name="login-email" :state="errors.length > 0 ? false:null" placeholder="john@clearspace.to" autofocus />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group>
                        <!-- <div class="d-flex justify-content-between">
                            <label for="password">Password</label>
                            <b-link :to="{name:'auth-forgot-password-v1'}">
                                <small>Forgot Password?</small>
                            </b-link>
                        </div> -->
                        <validation-provider #default="{ errors }" name="Password" rules="required">
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input id="password" v-model="password" :type="passwordFieldType" class="form-control-merge" :state="errors.length > 0 ? false:null" name="login-password" placeholder="Password - " />

                                <b-input-group-append is-text>
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- checkbox -->
                    <!-- <b-form-group>
                        <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                            Remember Me
                        </b-form-checkbox>
                    </b-form-group> -->

                    <!-- submit button -->
                    <b-button variant="primary" type="submit" block :disabled="invalid" @click="login">
                        Sign in
                    </b-button>
                </b-form>
            </validation-observer>

            <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

            <!-- social button -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
        </b-card>
        <!-- /Login v1 -->
    </div>
</div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        // BSV
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        VuexyLogo,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            email: '',
            password: '',
            status: '',
            // validation rules
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    beforeMount() {
    },
    methods: {
        login() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.$http.post('/login', {
                            email: this.email,
                            password: this.password
                        })
                    .then(response => {
                        const token = response.data.token
                        const name = response.data.user.first_name + ' ' + response.data.user.last_name;
                        const isAdmin = response.data.user.is_admin;
                        localStorage.setItem('token', token)
                        localStorage.setItem('name', name)
                        localStorage.setItem('isAdmin', isAdmin)
                        this.showToast('success','Welcome! You have successfully logged in');
                        this.$router.push({ path: '/' });
                    })
                    .catch(error => {
                        this.showToast('danger', 'Invalid Credentials')
                    });
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
